// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_ZR1Ch4GkpOaj_wvplYS2QUG9lWVNPZw",
  authDomain: "what-time-is-it2.firebaseapp.com",
  projectId: "what-time-is-it2",
  storageBucket: "what-time-is-it2.appspot.com",
  messagingSenderId: "829813079928",
  appId: "1:829813079928:web:8f7d0165bcb29d9070d28d",
  measurementId: "G-TBKEZ7D90G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
